import React, { useState, useEffect } from 'react';
import './variables.css';
import './global.css';
import Footer from './FooterNew';
import Mainnavigation from './NavigationNew';
import Spinner from './common/Spinner';

import { Box } from '@mui/material';

const Layout = ({ subcomponent, location, toastBarInfo }) => {
    const [pageLoading, setPageLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setPageLoading(false);
        }, 100);
    }, []);

    return pageLoading ? (
        <Spinner />
    ) : (
        <>
            <Mainnavigation location={location} toastBarInfo={toastBarInfo} />
            <Box component="main" sx={{ background: '#F7F1FD' }}>
                {subcomponent}
            </Box>
            <Footer location={location} />
        </>
    );
};

export default Layout;
