import React from 'react';
import Loader from '../../assets/loader-svg.svg';
import { Box, Grid } from '@mui/material';

export default function Spinner() {
    return (
        <Grid direction="column" display="flex" justifyContent="center" sx={{ height: '100vh' }} container>
            <Box display="flex" justifyContent="center">
                <Loader />
            </Box>
        </Grid>
    );
}
